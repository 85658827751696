var news = {
	date:'2021/02/10',
	title:'《异次元主公》预注册开跑',
	id:"2020110201",
	desc:"",
	showBg:"",
	info:`新的异次元秘境，崩坏的现实世界，异次元是什么，黑化的神明与过去的纠葛……历时3年研发，2022年2月米花互动科技将发布3D美少女战姬角色扮演卡牌手游——《异次元主公》。
预约注册即将正式启动，参与预约即可获得开服礼物，抢跑新手期。
顶级日配声优演绎特色美少女，UE4引擎精心制作宣传视频，明亮爽眼的游戏画面，作为指挥官的你需要引领麾下战姬，找回记忆，探寻天命之塔的顶峰。

参与预约的玩家将在游戏正式推出时最先收到提醒，下载游戏后即可收到开服礼物，特别的预约奖励和能力提升将带来更加爽快的游戏体验。礼物中更包含限定专属头像框，表现与众不同的身份。
背景设定：
「阿卡夏是宇宙所有显化现象的基础。它变成了所有显化的现象。据说它是如此微妙，以至于除非它在世上显化出来，否则它就无法被察觉。」
—— Nikola Tesla（1907）， 「Man’s Greatest Achievement」.

科技的无节制发展，看似繁荣的人类社会暗流涌动，被人性光辉所掩盖的，是灵魂的堕落与腐朽。

随著人类对于基础科学的究极探索终于触碰到了禁忌的领域，那只存在于神话当中的代表着另一个世界入口的「奥博之门」被再次开启。

随之而来的则是那古老圣书中记载的象征著「灾厄catastrophe」与「惩罚punishment」的「以太能量」开始扩散……

而终极武器「战姬」的降临，更将人类对于「力量」的渴求推向巅峰……

面对「以太能量」和「战姬」的诱惑，平日被秩序和道德压制的丑陋欲望在这一刻就像被海浪掀起来的淤泥，堂而皇之出现在了所有人面前。

阴谋，谎言，恶意，诋毁共同构成了新世界的主旋律……

为了挽救随时可能自我毁灭的人类，「诺亚小组」的指挥官在「战姬」鹤桥辉夜的陪伴下，登上了一切奥秘的终极「天命之塔」。

然而让他没想到的是，自己救世的举动，却让人类陷入了更加万劫不复的深渊……

真正的灾难，在这一刻降临了……
·舒爽轻质画风，带你重回初心找回快乐
回归二次元本真模样，不常见的亮色简洁扁平轻质感的立绘与UI，不仅是你的肝，你的头发，我们还要呵护你的眼！我们只想让你打开游戏，感受轻松。体验游戏最初的快乐。
·中日顶级声优，重现异世界罗曼物语
无论喜欢中文配音亦或是日文配音，都满足你！声情并茂体会奥博世界的罗曼。
中文声优：花玲，菊花花，梁爽，林簌，李蝉妃，mace，Sakula小舞，水原，陶典，钟可 等
日本声优阵容：井口裕香，上坂堇，植田佳奈，小倉唯，茅野爱衣，钉宫理惠，佐仓绫音，户松遥，悠木碧 等（敬称略・五十音順）
·新欢旧爱随心换，永远都有后悔药
不用小心翼翼计算升级材料，喜欢就养！不用延迟满足，当下就让喜欢的战姬成为最强，加入编队！想换新的战姬只要一键回退，后悔药想吃就吃，随时带着新战姬一起驰骋异世界。
·系统玩法多样，随你选择爽快翻倍
PVP与PVE系统多样，无论是想要“社交”还是“单机”都能得到满足。游戏就是要随你心意，不用被强制！走剧情，肝日常，养战姬，爬塔刷刷刷，护送物资还有更多系统开发中……
·3D智能一键战斗，自动施法不操心
3D即时战斗，4vs4~4vsN战姬团战一触即发。每位战姬至少拥有4个技能，智能演算最优施法，一键自律战斗，自动施法，开启绚丽技能。
`,
};

export{
	news
}