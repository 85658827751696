<template>
	<!-- content="width=device-width, initial-scale=1.0, user-scalable=no" -->
	<div id="m_root" >
		
		<!-- 樱花特效-->
		<!-- <div class="cherry" >
			<img id="yinghua" src="../../assets/mobile/yinghua.png" alt="">
		</div> -->
		
		<div v-if="isNewPage">
			<div id="m_newpage-root">
				<newpage :newid="newInfo.id" :title="newInfo.title" :date="newInfo.date" :info="newInfo.info"></newpage>
			</div>
		</div>


		<div v-show="!isNewPage" class="m_body">
			
			<!-- 樱花特效-->
			<!-- <div class="cherry" >
				<img id="yinghua" src="../../assets/mobile/yinghua.png" alt="">
			</div> -->

			<!-- <img id="xiaoguotu" src="../../assets/mobile/1.png" > -->

			<div id="top-root">
				<div class="root-bg" />
				<div class="title" />
				<!-- <div id="m_none"/> -->
				<div id="m_logo" />
				<div id="m_play" v-on:click="playVideo('https://youtu.be/5NbNM-ziA8E')" />
				<!--div id="m_appointment" v-on:click="getPreRegistration(1)"/-->
				<div id="m_appointment"
					 v-on:click="OnAppointmentClick()"/>
				<div class="m_download">
					<!-- app -->
					<a  class="item app fpoint" title="App Store"
						rel="nofollow" target="_blank" fpoint="center_menu_cms@module@AppStore"
						v-on:click="OnAppleClick()">
						</a>
					<!-- kunyue -->
					<a  class="item kunyue fpoint" title="kunyue"
						rel="nofollow" target="_blank" fpoint="center_menu_cms@module@kunyue"
						v-on:click="OnKunyueClick()">
						</a>
				</div>
				
				<!-- <div class="m_download_line2">
					<a  class="item leidian fpoint"
						title="ldplayer" rel="nofollow" target="_blank"
						fpoint="center_menu_cms@module@ldplayer"
						v-on:click="OnLeidianClick()">
						</a>
					
				</div>
				
				<div class="m_download_line3">
					<a  class="item bluestacks fpoint"
						title="bluestacks" rel="nofollow" target="_blank"
						fpoint="center_menu_cms@module@bluestacks"
						v-on:click="OnBluestacksClick()">
						</a>
					
				</div> -->

			</div>

			<div id="reward-root">
				
				<div class="reward-bg">
					<div class="t-reward title" />
					
						<div class="reward-detail" >
						
							<div class="reward-item" v-for="item in rewardThresholdList">
							
								<img class="reward-item-img" :src="require(`../../assets/mobile/${item.img_name}`)" alt="" />

								<img class="reward-completed-item" :src="require(`../../assets/mobile/reward_completed.png`)" alt=""
								v-if="item.num <= preRegistrationNum ? true : false">
								</img>
							
						</div>
					</div>
				</div>
			</div>


			<!-------------------  ------------------！-->
			<div id="news-root">
				
				<div class="news-bg">
					
					<div class="t-news title" />
					
					<div class = "news-top" >
						<div class="swiper-container" id="newsTop">
							<div class="swiper-wrapper">
								<div class="swiper-slide" v-for="item in newsTopList">
									<img class="news-top-stage" :src="item.img">
								</div>
							</div>
						</div>
					</div>
					
					
					<ul class="news_list">
						<li class="item" v-for="(item,index) in dataNewsList"
							v-if="(index>=newsPage*newsViewNum)&&(index<newsPage*newsViewNum+newsViewNum)"
							v-on:click="newClick(item)">
							<p class="n_date">{{item.date}}</p>
							<p class="n_title">{{ellipsis(item.title)}}</p>
						</li>
						
						<div class="news_page">
							<div :class="item!=newsPage?'item':'item active_item'" v-for="item in newsPages"
								v-on:click="newPageClick(item)">
								{{item+1}}
							</div>
						</div>
						
					</ul>
					
					
				</div>
				
			</div>
			
			
			<div id="feature-root">
				
				<div class="feature-bg">
				<div class="t-feature title" />
				
				<div class="swiper-container" id="mfeatures">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="item in featureList">
							<img class="mfstage" :src="item">
						</div>
							
					</div>
				</div>
				
				</div>
			
			</div>
			
			
			
			<div id="role-root">
				
				<div class="role-bg">
					<div class="t-role title" />
					
					<!-- 角色列表 -->
					<div class="swiper-container mroles" id="mroleList">
						<div class="swiper-wrapper">
							<div class="swiper-slide" v-for="item in roleList" :key="item.name">
								<img class="stage" :src="item.img" :alt="item.name">
								<div class="info">
									
									<!-- <p class="name">{{item.name}}</p>
									<p class="author"> CV: {{item.cv}}</p> -->
									
									<img id="mrole-info" :src="item.rinfo" alt="" />
									
									<!--p class="description">{{item.des}}</p-->
								</div>
							</div>
						</div>
					</div>
					
					<div id = "mrole-stage-bg"></div>
					
					<!-- 头像列表 -->
					<div class="swiper-pagination mavatars" id="mavatarBox"></div>
					<!-- 人物介绍背景 -->
					<!--div class="info_bg"></div-->
					<!-- 左右按钮 -->
					<div class="mbtns container">
						<a href="javascript:;" class="btn btn-left fpoint" id="mrolePrevBtn"
							fpoint="page_cms_ads@previous@character"></a>
						<a href="javascript:;" class="btn btn-right fpoint" id="mroleNextBtn"
							fpoint="page_cms_ads@next@character"></a>
					</div>
					
				</div>
				
			</div>

			<div id="story-root">
				
				<div class="story-bg">
					<div class="t-story title" />
					
					<div id ="story-content">
						
						<!-- <div class="swiper-pagination " id=""></div> -->
						
						<div class="story-content-item" v-for="item in storyBGList" >
							<img class="story-content-img" :src="item.img" alt=""/>
							
						</div>
						
						<!-- <div class="story-img">
							<div class="vplay-btn" v-on:click="playVideo('https://www.youtube.com/embed/MtMO-gBF3pQ')"></div>
						</div>
						<div class="story-info" /> -->
						
					</div>
					
				</div>
				
			</div>

			
		</div>
		<div class="m_copyright">
			<div id="m_none" />
			<p class="yszc" v-on:click="yszcClick()">隐私政策</p>
		</div>

		<!--导航弹窗-->
		<div class="menu_open" v-on:click="openMenu()"></div>

		<div class="nav_mark" id="navModal" v-if="isShowMenu">
			<div class="menu_list">
				
				<div :class="selectIndex!=index?'item':'item item_active'"
				v-for="(item,index) in menuList" 
				v-on:click="menuClick(item)">
					<img id="menu-item-img" :src="require(`../../assets/mobile/${item.name_cn}`)" alt="" />
				</div>
				
			</div>
			<div class="menu_close_btn" v-on:click="closeMenu()"></div>
		</div>

		<!--视频播放-->
		<div class="mask video" id="videoModal" v-show="isShowVideo">
			<button class="close_btn vclose" v-on:click="closeVideo"></button>
			<iframe class="iframe" :src="videoUrl" frameborder="0" id="videoFrame"></iframe>
		</div>

		<!--提示竖屏浏览-->
		<div class="mask" v-if="isShowOrientLayer">
			<div class="orient_layer">
				<i class="orient_layer_icon"></i>
				<p>請豎屏瀏覽此頁面</p>
			</div>
		</div>
		
		
	</div>
</template>


<script>
	import newpage from './newpage.vue'
	import axios from 'axios'
	import data from '../../data/data.vue'
	
	export default {
		components: {
			newpage
		},
		data() {
			return {
				dataNewsList: data.get_news_list(),
				preRegistrationNum: 0,
				selectIndex: 0,
				isShowMenu: false,
				isShowVideo: false,
				isShowOrientLayer: false,
				newsPage: 0,
				newInfo: Object,
				newsViewNum: 4,
				isNewPage: false,
				videoUrl: '',
				roleList: [{
						name: "雲上武神",
						cv: "川澄綾子",
						des: "青羽的第三形態，終於到達了武的巔峰，成為真正的武神形態，此時她的氣質卻隱去了外在的鋒芒，轉為內在的強大和平靜",
						img: require("../../assets/mobile/role1.png"),
						hicon: require("../../assets/mobile/hicon1.png"),
						rinfo: require("../../assets/mobile/rinfo1.png")
					},
					{
						name: "十方皆斬",
						cv: "釘宮理惠",
						des: "雷琪爾的第二形態，使用名為“斬滅”的三把太刀進行戰鬥，對弱者毫不留情，對毀滅敵人一事十分熱衷",
						img: require("../../assets/mobile/role2.png"),
						hicon: require("../../assets/mobile/hicon2.png"),
						rinfo: require("../../assets/mobile/rinfo2.png")
					},
					{
						name: "詭謀冥燈",
						cv: "悠木碧",
						des: "跟隨克莉絲四處征戰的天樞參謀，具有僅次於妮莎的智謀，真是目的和身份成謎，本是關心他人的溫柔戰姬，卻不知為何幾乎在一夜之間轉為了惡劣殘忍的性格",
						img: require("../../assets/mobile/role3.png"),
						hicon: require("../../assets/mobile/hicon3.png"),
						rinfo: require("../../assets/mobile/rinfo3.png")
					}
					
				],
				rewardThresholdList:[
					{
						img_name: "reward1.png",
						num: 100000
					},
					{
						img_name: "reward2.png",
						num: 150000 
					},
					{
						img_name: "reward3.png",
						num: 200000 
					},
					{
						img_name: "reward4.png",
						num: 250000 
					},
					{
						img_name: "reward5.png",
						num: 300000 
					}
				],
				storyBGList:[
					{
						img: require("../../assets/mobile/story_pic_1.png")
					},
					{
						img: require("../../assets/mobile/story_pic_2.png")
					},
					{
						img: require("../../assets/mobile/story_pic_3.png")
					},
					{
						img: require("../../assets/mobile/story_pic_4.png")
					},
					{
						img: require("../../assets/mobile/story_pic_5.png")
					}
				],
				newsPages: [],
				newsTopList: [
					{
					img: require('../../assets/mobile/newsTop1.jpg')
					},
					{
					img: require('../../assets/mobile/newsTop2.jpg')
					},
					{
					img: require('../../assets/mobile/newsTop3.jpg')
					},
					{
					img: require('../../assets/mobile/newsTop4.jpg')
					},
					{
					img: require('../../assets/mobile/newsTop5.jpg')
					}
				],
				featureList: [
					require('../../assets/mobile/feature1.jpg'),
					require('../../assets/mobile/feature2.jpg'),
					require('../../assets/mobile/feature3.jpg'),
					require('../../assets/mobile/feature4.jpg'),
					require('../../assets/mobile/feature5.jpg')
				],
				menuList: [{
						name_cn: "首頁.png",
						name_en: "TOP",
						jumpid: "#top-root"
					},
					{
						name_cn: "獎勵.png",
						name_en: "REWARD",
						jumpid: "#reward-root"
					},
					{
						name_cn: "新聞.png",
						name_en: "NEWS",
						jumpid: "#news-root"
					},
					{
						name_cn: "遊戲特色.png",
						name_en: "FEATURE",
						jumpid: "#feature-root"
					},
					{
						name_cn: "人物介紹.png",
						name_en: "CHARACTER",
						jumpid: "#role-root"
					},
					{
						name_cn: "世界觀.png",
						name_en: "STORY",
						jumpid: "#story-root"
					}
					/*,
									{name_cn:"充值中心",name_en:"STORE"}*/
				]
			};
		},
		methods: {
			OnAppointmentClick: function(){
				console.log("OnAppointmentClick==>");
				window.open("https://www.taptap.com/app/194962");
			},
			OnThunderClick: function(){
				console.log("OnThunderClick==>");
				window.open("https://www.ldplayer.tw/games/com-kunyue-ecyakxjh-on-pc.html");
			},
			OnAppleClick: function(){
				console.log("OnAppleClick==>");
				window.open("https://apps.apple.com/cn/app/id1536870131");
			},
			OnGoogleClick: function(){
				console.log("OnGoogleClick==>");
				gtag_report_conversion("https://app.adjust.com/g1l48ec?redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.kunyue.ecyakxjh");
			},
			OnLeidianClick: function(){
				console.log("OnLeidianClick==>");
				gtag_report_conversion("https://www.ldplayer.tw/games/com-kunyue-ecyakxjh-on-pc.html");
			},
			OnKunyueClick: function(){
				console.log("OnKunyueClick==>");
				window.open("https://www.taptap.com/app/194962");
			},
			OnBluestacksClick: function(){
				console.log("OnBluestacksClick==>");
				gtag_report_conversion("https://wp-s.bluestacks.com/tw/apps/role-playing/a-ka-xia-jihua-on-pc.html");
			},
			yszcClick: function() {
				window.open('http://resource2.mihuahudong.com/website/privacy-policy.html');
			},
			newClick: function(newInfo) {
				this.newInfo = newInfo;
				this.isNewPage = true;
				$("html, body").animate({
					scrollTop: 0
				}, {
					duration: 0,
					easing: "swing"
				});
			},
			newPageClick: function(pageIndex) {
				this.newsPage = pageIndex;
			},
			openMenu: function() {
				this.isShowMenu = true;
			},
			closeMenu: function() {
				this.isShowMenu = false;
			},
			menuClick: function(menuInfo) {
				this.newInfo = undefined;
				this.isNewPage = false;
				setTimeout(function() {
					if (menuInfo.jumpid) {
						$("html, body").animate({
							scrollTop: $(menuInfo.jumpid).offset().top
						}, {
							duration: 200,
							easing: "swing"
						});

					} else {

					}
				});

				this.isShowMenu = false;
			},
			onScroll: function() {
				if (this.isNewPage)
					return;
				var currentOffset = document.documentElement.scrollTop;
				var index = 0;
				var menuHeight = 0.86 * parseInt(document.documentElement.style.fontSize.replace('px', ''));
				for (index = 0; index < this.menuList.length; index++) {
					if (this.menuList[index].jumpid) {
						if (currentOffset < $(this.menuList[index].jumpid).offset().top - menuHeight) {
							break;
						}
					} else {
						break;
					}
				}
				if (index > 0) {
					index--;
				}
				this.selectIndex = index;
			},
			playVideo: function(url) {
				if (this.videoUrl != url) {
					this.videoUrl = url;
				}
				this.isShowVideo = true;
			},
			closeVideo: function() {
				this.isShowVideo = false;
				this.videoUrl = "";
			},
			onResize: function() {
				var width = document.documentElement.clientWidth;
				var height = document.documentElement.clientHeight;

				this.isShowOrientLayer = width > 750 || width > height;
			},
			ellipsis: function(value){				if (!value) return '';				  if (value.length > 20) {					return value.slice(0,20) + '...'				  }				  return value			},
			getPreRegistration: function(times){
				console.log("getPreRegistration==>" + times)
				// let self = this;
				axios.post('https://ssl.mihuahudong.com:21020/ecy_fz_record/back',{
								  times: times
						})
					.then((res)=>{
							console.log(res);
								  
							if(res.status == 200){
								if(res.data.code==0){
									// this.preRegistrationNum = parseInt(res.data.totalCount);
									// self.preRegistrationNum = res.data.totalCount;
									this.preRegistrationNum = res.data.totalCount;
									console.log("this.preRegistrationNum==>"+this.preRegistrationNum);
									
								}else{
									console.log("totalCount请求失败 error code:"+res.data.code);
									}
							}else{
								console.log("人数状态有误 error status:"+res.status);
							}
						})
					.catch(function(err){
								console.log(err);
						});	
			},
			//樱花飘落特效
			Cherry_Blossoms: function() {
			
				//设置默认值
				var defaults = {
					ele: '.cherry', //切换对象
					is_Cherry: true, //是否生成樱花
					image_min: 10, //花瓣最小宽度和高度
					image_max: 50, //花瓣最大宽度和高度
					time_min: 10000, //花瓣最快下坠时间
					time_max: 20000, //花瓣最慢下坠时间
					interval: 500 //花瓣生成时间间隔
				};
				console.log("设置默认值f")
				var params = {
					ele: '.cherry', //切换对象
					is_Cherry: true, //是否生成樱花
					image_min: 10, //花瓣最小宽度和高度
					image_max: 50, //花瓣最大宽度和高度
					time_min: 10000, //花瓣最快下坠时间
					time_max: 20000, //花瓣最慢下坠时间
					interval: 500 //花瓣生成时间间隔
				};
				//定义默认参数，其中若在html页面设置了param是，这里的params会替换defaults
				var par = $.extend({}, defaults, params);
				/**
				 * [m,n]随机数函数
				 */
				function randomNum(minNum, maxNum) {
					switch (arguments.length) {
						case 1:
							return parseInt(Math.random() * minNum + 1, 10);
							break;
						case 2:
							return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
							break;
						default:
							return 0;
							break;
					}
				}
			
				/**
				 * 生成樱花花瓣
				 */
				if (par.is_Cherry) {
			
					console.log("生成樱花花瓣")
			
					setInterval(function() {
						var time; //下落的持续时间，取随机数
						var image_daxiao; //花瓣大小取随机数
						var margin_left; //花瓣所在位置，取随机数
						margin_left = randomNum(5, 100);
						time = randomNum(par.time_min, par.time_max);
						//console.log(time);
						image_daxiao = randomNum(par.image_min, par.image_max);
						//console.log(image_daxiao);
						//console.log($('#yinghua:last-child'));
						//注意此处的加载路径问题
						$('#yinghua:last-child').after(
							'<img id="yinghua" src="/img/yinghua.2f500675.png" alt="">');
						$('#yinghua:last-child').css({
							'margin-left': margin_left + '%',
							'width': image_daxiao + 'px',
							'height': image_daxiao + 'px'
						});
						$('#yinghua:last-child').animate({
							left: -15 + '%',
							top: 100 + '%',
							deg: -540
						}, {
							step: function(deg) {
								//console.log(deg);
								$(this).css({
									"transform": "rotate3d(1,.2,0," + deg + "deg)"
								});
							},
							duration: time
						}, time);
						setTimeout(function() {
							$('#yinghua:first-child').remove();
						}, time - 5000);
					}, par.interval);
				}
			}
		},
		created() {
			// this.Cherry_Blossoms();
			this.getPreRegistration(0);
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.onResize, false);
			window.removeEventListener("scroll", this.onScroll);
		},
		mounted() {

			window.addEventListener("resize", this.onResize, false);
			this.onResize();
			
			window.addEventListener("scroll", this.onScroll, false);
			this.onScroll();


			var pages = Math.ceil(this.dataNewsList.length / this.newsViewNum);

			for (var i = 0; i < pages; i++) {
				this.newsPages.push(i);
			}

			var _this = this;
			// 初始化角色轮播
			var roleSwiper = new Swiper('#mroleList', {
				autoplay: 2000,
				speed: 1500,
				loop: true,
				pagination: '#mavatarBox',
				prevButton: '#mrolePrevBtn',
				nextButton: '#mroleNextBtn',
				paginationClickable: true,
				paginationBulletRender: function(swiper, index, className) {
					return '<span class="' + className + ' item"><span class="border"></span><img src=' + _this
						.roleList[index].hicon + ' /></span>';
				}
			});

			// 初始化游戏系统轮播
			var systemSwiper = new Swiper('#mfeatures', {
				autoplay: 2000,
				ection: 'horizontal',
				loop: true,
				effect: 'coverflow',
				centeredSlides: true,
				slidesPerView: 'auto',
				coverflow: {
					rotate: 25,
					stretch: 0,
					depth: 110,
					modifier: 2,
					slideShadows: false
				}
			});
			
			// 初始化新闻系统轮播
			var nesSwiper = new Swiper('#newsTop', {
				autoplay: 2000,
				ection: 'horizontal',
				loop: true,
				effect: 'coverflow',
				centeredSlides: true,
				slidesPerView: 'auto',
				coverflow: {
					rotate: 25,
					stretch: 0,
					depth: 110,
					modifier: 0,
					slideShadows: false
				}
			});

		}
	}
</script>

<style>
	
	#m_root {
		/* min-width: 1080px;
		text-align: center;
		overflow:scroll;
		overflow-x:hidden; */
		overflow-x:hidden;
	}
	
	
	.cherry img {
		width: 50px;
		height: 50px;
		margin-top: -5%;
		opacity: 0.5;
		position: absolute;
		z-index: 99;
		overflow-x:hidden;
		/* overflow-x: hidden;
		overflow-y: hidden; */
	}
	
	/* #yinghua {
		overflow-x: hidden;
		overflow-y: hidden;
	} */
	
	.m_body {
		/* width: 100%;
		height: 535.0666666666vw;
		text-align: center;
		background-repeat: no-repeat;
		background-position: 0 0;
		background-image: url(../../assets/mobile/main-bg.jpg);
		background-size: 100% 100%;
		font-size: 18px; */
		/* touch-action: none; */ 
		position: relative;
		margin: 0 auto;
		width: 100%;
		height: 100%;
		/* overflow-y:hidden; */
		overflow-x:hidden;

	}

	#top-root {
		/* height: 100%; */
		/* overflow-x:hidden; */
		
	}

	#top-root .root-bg {
		width: 100vw;
		height: 100vh;
		position: relative;
		left: 0;
		top: 0;
		background: url(../../assets/mobile/bg_1.png) no-repeat;
		background-size: cover;
		background-position: top center;
		overflow: hidden;
	}

	.reward-bg {
		width: 100vw;
		height: 100vh;
		position: relative;
		left: 0;
		top: 0;
		background: url(../../assets/mobile/bg_2.png) no-repeat;
		background-size: cover;
		background-position: top center;
		overflow: hidden;
	}
	
	.reward-detail{
		position: absolute;
		top: 1.6rem;
		left: 6.5%;
	}
	
	.reward-detail .reward-item{
		margin: 0.3rem 0rem;
	}
	
	
	.reward-item .reward-completed-item{
		position: absolute;
		margin: 0.2rem -4.6rem;
		height: 1.2rem;
		width: 1.42rem;
	}
	
	.reward-item .reward-item-img{
		height: 1.9rem;
		width: 6.5rem;
	}

	#m_none {
		width: 1px;
		height: 1px;
		background-color: #000000;
	}

	#m_logo {
		position: absolute;
		left: 48%;
		top: 1.2rem;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 6rem;
		height: 4rem;
		background: url(../../assets/mobile/logo.png);
		background-size: contain;
		background-repeat: no-repeat;

	}

	#m_play {
		position: absolute;
		left: 52%;
		/* top: 3.3rem; */
		top: 3rem;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 2.3rem;
		height: 2.3rem;
		background: url(../../assets/mobile/play-btn.png);
		background-size: contain;
		background-repeat: no-repeat;
		cursor: pointer;
	}

	#m_appointment {
		position: absolute;
		left: 50%;
		/* top: 6.5rem; */
		top: 5.6rem;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 4.8rem;
		height: 1rem;
		background: url(../../assets/mobile/appointment.png);
		background-size: contain;
		background-repeat: no-repeat;
		cursor: pointer;
	}
	
	.facebook_fans {
		position: absolute;
		top: 9.4rem;
		width: 100%;
		height: 1rem;
		/* border: 1px solid red; */
	}
	
	.fb-container {
		
	}

	.m_download {
		position: absolute;
		/* top: 8.2rem; */
		top: 7rem;
		width: 100%;
		height: 1rem;
	}
	
	
	.m_download_line2 {
		position: absolute;
		top: 8.5rem;
		width: 100%;
		height: 1rem;
	}
	
	.m_download_line3 {
		position: absolute;
		top: 10rem;
		width: 100%;
		height: 1rem;
	}
	
	.m_download_line3 .item {
		display: inline-block;
		width: 3.3rem;
		height: 1rem;
		margin: auto 0.2rem;
	}
	
	.m_download_line2 .item {
		display: inline-block;
		width: 3.3rem;
		height: 1rem;
		margin: auto 0.2rem;
	}
	

	.m_download .item {
		display: inline-block;
		width: 3.3rem;
		height: 1rem;
		margin: auto 0.2rem;
	}

	.m_download .kunyue {
		background-image: url(../../assets/mobile/kunyuedown-btn2.png);
		background-size: 100% 100%;
	}

	.m_download .app {
		background-image: url(../../assets/mobile/adownload-btn2.png);
		background-size: 100% 100%;
	}
	.m_download_line2 .leidian {
		background-image: url(../../assets/mobile/lddown-btn.png);
		background-size: 100% 100%;
	}
	
	.m_download_line3 .bluestacks {
		background-image: url(../../assets/mobile/bluestacks-btn.png);
		background-size: 100% 100%;
	}

	/* .top-down{
		width:0.75rem;
		height:0.58rem;
		background-image: url(../../assets/mobile/down-btn.png);
		background-size:100% 100%;
		margin: auto 3.375rem;
		margin-top: 1.26rem;
	} */


	.title {
		/* width:4.52rem;
		height:1.38rem;
		margin: auto 1.49rem;
		margin-top: 0.1rem; */
		
		position: absolute;
		left: 38%;
		top: 0.6rem;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		/* width: 5.6rem;
		height: 1rem; */
		width: 80vw;
		height: 80vh;
	}
	
	.t-reward {
		/* background-image: url(../../assets/mobile/title-reward.png);
		background-size:100% 100%; */
		
		/* position: absolute;
		left: 36%;
		top: 0.8rem;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 5.8rem;
		height: 1rem; */
		background: url(../../assets/mobile/title-reward.png);
		background-size: contain;
		background-repeat: no-repeat;
		
	}

	.t-news {
		/* background-image: url(../../assets/mobile/title-news.png);
		background-size:100% 100%; */
		background: url(../../assets/mobile/title-news.png);
		background-size: contain;
		background-repeat: no-repeat;
	}

	.t-story {
		/* background-image: url(../../assets/mobile/title-story.png);
		background-size: 100% 100%; */
		background: url(../../assets/mobile/title-story.png);
		background-size: contain;
		background-repeat: no-repeat;
	}

	.t-role {
		/* background-image: url(../../assets/mobile/title-heros.png);
		background-size: 100% 100%; */
		background: url(../../assets/mobile/title-heros.png);
		background-size: contain;
		background-repeat: no-repeat;
	}

	.t-feature {
		/* margin-top: 0.3rem;
		background-image: url(../../assets/mobile/title-feature.png);
		background-size: 100% 100%; */
		
		background: url(../../assets/mobile/title-feature.png);
		background-size: contain;
		background-repeat: no-repeat;
	}

	/**news start**/
	.news-bg {
		width: 100vw;
		height: 100vh;
		position: relative;
		left: 0;
		top: 0;
		background: url(../../assets/mobile/bg_3.png) no-repeat;
		background-size: cover;
		background-position: top center;
		overflow: hidden;
	}
	
	.feature-bg{
		width: 100vw;
		height: 100vh;
		position: relative;
		left: 0;
		top: 0;
		background: url(../../assets/mobile/bg_4.png) no-repeat;
		background-size: cover;
		background-position: top center;
		overflow: hidden;
	}
	
	.role-bg{
		width: 100vw;
		height: 100vh;
		position: relative;
		left: 0;
		top: 0;
		background: url(../../assets/mobile/bg_5.png) no-repeat;
		background-size: cover;
		background-position: top center;
		overflow: hidden;
	}
	
	.story-bg{
		width: 100vw;
		height: 100vh;
		position: relative;
		left: 0;
		top: 0;
		background: url(../../assets/mobile/bg_6.png) no-repeat;
		background-size: cover;
		background-position: top center;
		overflow: hidden;
	}
	
	.story-bg #story-content{
		width: 100%;
		height: 100%;
		position: relative;
		top: 12%;
		/* background-color:#000000; */
	}
	
	.story-content-item{
		
		/* position: absolute; */
		margin: 0.1rem 1rem;
		width: 5.6rem;
		height: 2.2rem;
		background-size: cover;
		background-position: top center;
		
	}
	
	.story-content-item .story-content-img{
		width: 100%;
		height: 100%;
	}
	
	.news_list_root{
		
	}
	
	/* .swiper-container-3d {
		pointer-events: none;
	}
	.swiper-wrapper{
		
	} */

	.news_list {
		/* width: 5.28rem;
		height: 3.24rem;
		padding-top: 0.36rem;
		padding-left: 0.45rem;
		font-size: 0.18rem;
		font-family: "SourceHanSerifCN-Bold"; */
		
		position: absolute;
		left: 6.2%;
		top: 3.8rem;
		/* -webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%); */
		width: 5.8rem;
		height: 6.5rem;
		font-size: 0.18rem;
		
		background: url(../../assets/mobile/newsList-bg.png) no-repeat;
		background-size: cover;
		background-position: top center;
	}

	.news_list .item {
		height: 0.08rem;
		margin: 1.2rem -0.8rem;
		padding: 0;
		text-align: left;
		cursor: pointer;
		list-style-type: none;
		z-index: 6;
	}

	.news_list .item p {
		/* padding-left: 0.14rem; */
	}

	.news_list .item .n_date {
		
		color: #4f4460;
		padding-top: 0.1rem;
		margin: auto 0.5rem;
		/* padding-top: 0.08rem;
		margin: auto 4.8rem; */
		
	}

	.news_list .item .n_title {
		color: #4f4460;
		padding-top: 0.1rem;
		margin: auto 0.6rem;
		width: 5.8rem;
		/* height: 2rem; */
	}

	.news_page {
		margin-top: 1.1rem;
		margin-right: 1.5rem;
	}

	.news_page .item {
		display: inline-block;
		/* height: 0.26rem; */
		cursor: pointer;
		margin: 0 0.10rem;
		font-size: 0.22rem;
		color: #55aaff;
	}

	.news_page .active_item {
		color: #55ffff;
	}
	
	.news-facebook-page{
		
		position: absolute;
		left: 50%;
		top: 8.8rem;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 6.5rem;
		height: 5rem;
		/* background: url(../../assets/mobile/news_facebook_page.png);
		background-size: contain;
		background-repeat: no-repeat;
		cursor: pointer; */
		
	}

	/**news end**/

	/**story start**/
	.story-img {
		width: 5.60rem;
		height: 4.28rem;
		background-image: url(../../assets/mobile/vplay-bg.png);
		background-size: 100% 100%;
		margin: 0.5rem 0.95rem;
	}

	.story-img .vplay-btn {
		width: 0.61rem;
		height: 0.61rem;
		background-image: url(../../assets/mobile/vplay-btn.png);
		background-size: 100% 100%;
		margin: auto 2.5rem;
		position: relative;
		top: 1.835rem;
		cursor: pointer;
	}

	.story-info {
		width: 5.94rem;
		height: 4.22rem;
		background-image: url(../../assets/mobile/storyinfo.png);
		background-size: 100% 100%;
		margin: auto 0.78rem;
	}

	/**story end**/
	.mroles {
		margin-top: -0.3rem;
	}

	.mroles .stage {
		/* display: block;
		margin: 0 auto;
		height: 5.9rem; */
		
		display: block;
		margin: 1rem auto;
		width: 7.2rem;
		height: 12.2rem;
		
	}
	
	
	#mrole-stage-bg{
		position: absolute;
		left: 50%;
		top: 10rem;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 6rem;
		height: 5rem;
		background: url(../../assets/mobile/stage-bg.png);
		background-size: contain;
		background-repeat: no-repeat;
	}

	/* .roles .swiper-slide-active .info {
		animation: infobg 0.5s ease 1.5s forwards;
	} */
	
	#mrole-info{
		position: absolute;
		left: 75%;
		top: 9rem;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 3rem;
		height: 2.2rem;
		
	}

	/* .roles .info .name {
		font-size: 0.56rem;
		line-height: 0.3rem;
		color: #f9fffa;
		text-shadow: 0px 0px 0.08rem #aa00ff;
	} */

	/* .roles .info .author {
		white-space: pre-wrap;
		margin-left: 0.1rem;
		font-size: 0.2rem;
		color: #cf62f1;
		font-weight: bold;
		line-height: 0rem;
		margin-top: -0.16rem;
		text-shadow: 0px 0px 0.08rem #ff00ff;
	} */

	/* .roles .info .description {
		margin-top: 0.98rem;
		margin-left: 0.1rem;
		height: 3rem;
		color: #fdffef;
		text-shadow: 1px 1px 0.05rem #aa00ff;
		font-size: 0.20rem;
		line-height: 0.34rem;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		width: 2.15rem;
	} */

	#role-root .mavatars {
		height: 0.9rem;
		margin: 0rem auto;
		text-align: center;
		width: 100%;
	}

	#role-root .mavatars .item {
		width: 1.33rem;
		height: 1.16rem;
		border-radius: 0;
		cursor: pointer;
		background: transparent;
		opacity: 1;
		margin: 0 0.2rem;
		position: relative;
	}

	#role-root .mavatars .item .border {
		
		top: -0.11rem;
		left: -0.12rem;
		display: inline-block;
		width: 1.56rem;
		height: 1.4rem;
		position: absolute;
		/* background-image: url(../../assets/mobile/hicon-kuang-bai.png); */
		background-size: 100% 100%;
	}

	#role-root .mavatars .item img {
		width: 100%;
		height: 100%;
	}
	
	#mavatarBox {
		position: absolute;
		left: 50%;
		top: 11.2rem;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 6rem;
		height: 5rem;
		
	}

	#role-root .mavatars .item.swiper-pagination-bullet-active .border {
		/*border-color: #6f00a6;*/
		/* top: -0.03rem;
		left: -0.02rem; */
		background-image: url(../../assets/mobile/hicon-kuang-zi.png);
		background-size: 100% 100%;
	}

	#role-root .mavatars .item.swiper-pagination-bullet-active::before {
		content: '';
		width: 0.58rem;
		height: 0.18rem;
		position: absolute;
		/*background-image: url(../images/imgs.png);*/
		background-repeat: no-repeat;
		background-position: -3.92rem -2.29rem;
		background-size: 4.95rem;
		left: 0rem;
		bottom: 0.06rem;
		z-index: 1;
	}

	#role-root .mavatars .item.swiper-pagination-bullet-active::after {
		content: '';
		width: 0.58rem;
		height: 0.18rem;
		position: absolute;
		/*background-image: url(../images/imgs.png);*/
		background-repeat: no-repeat;
		background-position: -3.32rem -2.29rem;
		background-size: 4.95rem;
		right: -0.04rem;
		top: 0.0rem;
		z-index: 2;
	}

	#role-root .mbtns {
		position: relative;
		height: 5.9rem;
		width: 7.5rem;
		left: 0;
		margin-top: -4.4rem;
		top: 0rem;
	}

	#role-root .mbtns .btn {
		width: 0.6rem;
		height: 0.8rem;
		background-repeat: no-repeat;
		background-size: 4.95rem;
		position: absolute;
		top: 1.95rem;
		z-index: 99;
	}

	#role-root .mbtns .btn.btn-left {
		background-image: url(../../assets/mobile/l-btn.png);
		background-size: 100% 100%;
		left: 0.65rem;
	}

	#role-root .mbtns .btn.btn-right {
		background-image: url(../../assets/mobile/r-btn.png);
		background-size: 100% 100%;
		right: 0.65rem;
	}

	/********roles end********/
	#mfeatures {
		/* border: 1px solid red; */
		display: block;
		margin: 1.6rem auto; 
		width: 6.6rem;
		height: 12rem;
	}
	
	#mfeatures .mfstage {
		
		/* width: 100%; */
		/* display: block;
		margin: 2rem auto; 
		width: 6.6rem;
		height: 10rem; */
		position: absolute;
		display: block;
		margin: 0rem auto;
		width: 6.6rem;
		height: 11rem;
		/* border: 1px solid green; */
	}
	
	.news-top{
		pointer-events: none;
		margin: 1.8rem auto;
		height: 1.6rem;
	}
	
	#newsTop {
		width: 6.6rem;
		height: 1.8rem;
		/* border: 1px solid yellow; */
	}
	
	#newsTop .news-top-stage {
		width: 100%;
		height: 100%;
		object-fit:cover;
		/* border: 1px solid green; */
	}

	.m_copyright {
		margin-top: -0.06rem;
		width: 100%;
		height: 67.06666666666vw;
		background-image: url(../../assets/mobile/copyright.png);
		background-size: 100% 100%;
	}

	.m_copyright .yszc {
		cursor: pointer;
		font-size: 0.24rem;
		color: #fffcf9;
		margin-top: 3.98rem;
		margin-left: 5rem;
	}


	.menu_open {
		cursor: pointer;
		position: fixed;
		top: 0.2rem;
		right: 0.2rem;
		width: 550px;
		z-index: 99;
		width: 0.65rem;
		height: 0.62rem;
		background-image: url(../../assets/mobile/menus-open-btn.png);
		background-size: 100% 100%;
	}
	
	.nav_mark {
		position: fixed;
		left: 50%;
		top: 0;
		z-index: 999;
		width: 50vw;
		height: 100vh;
		background: url(../../assets/mobile/nav-bg.png) no-repeat;
		background-size: cover;
		-webkit-transition: 0.3s;
		transition: 0.3s;
		/* overflow: hidden; */
	}


	.mask {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.85);
		z-index: 999;
		padding-top: 0.1rem;
	}

	.nav_mark .menu_list {
		position: absolute;
		    /* rightt: 0; */
		    top: 2.3rem;
		    width: 3.8rem;
		    height: 6.8rem;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-pack: justify;
		        -ms-flex-pack: justify;
		            justify-content: space-between;
		    -webkit-box-orient: vertical;
		    -webkit-box-direction: normal;
		        -ms-flex-direction: column;
		            flex-direction: column;
	}
	
	.menu_list .item_active {
		display: block; 
		background: url(../../assets/mobile/117.png) no-repeat;
		background-size: cover;
	}

	.nav_mark .menu_list .item {
		
		padding: 0;
		cursor: pointer;
		height: 1rem;
		list-style-type: none;
	}
	
	#menu-item-img {
		position: relative;
		top: 0.3rem;
		width: 0.8rem;
	}

	/* .mask .menu_list .item .h5 {
		font-size: 0.24rem;
		line-height: 0.24rem;
		font-weight: 500;
		height: 0rem;
	} */

	/* .mask .menu_list .item .h6 {
		font-size: 0.16rem;
		line-height: 0.16rem;
		margin-top: 0.34rem;
		font-weight: 500;
		color: #aea2a4;
	} */

	/* .mask .menu_list .item .gapline {
		width: 6.5rem;
		height: 1px;
		margin: 0rem 0.5rem;
		margin-top: 0.3rem;
		background: linear-gradient(to right,
				rgba(174, 162, 164, 0.0) 0%,
				rgba(174, 162, 164, 0.6) 1.4%,
				rgba(174, 162, 164, 0.6) 8%,
				rgba(174, 162, 164, 0.6) 92%,
				rgba(174, 162, 164, 0.6) 98.6%,
				rgba(174, 162, 164, 0.0) 100%);
	} */

	.mask .close_btn {
		cursor: pointer;
		width: 0.75rem;
		height: 0.74rem;
		margin: 0rem 3.375rem;
		margin-top: 0.3rem;
		background-image: url(../../assets/mobile/meuns-close-btn.png);
		background-size: 100% 100%;
	}
	
	.menu_close_btn {
		position: absolute;
		left: 1.65rem;
		top: 9.3rem;
		width: 0.4rem;
		height: 0.43rem;
		background: url(../../assets/mobile/meuns-close-btn.png);
		background-size: cover;
		cursor: pointer;
		/* z-index: 1000; */
	}

	.mask .vclose {
		top: 1.22rem;
		right: -3.2rem;
		position: absolute;
		border: none;
		background-color: #00000000;
	}

	#videoModal {
		padding-top: 2.44rem;
	}

	.video .iframe {
		width: 7.5rem;
		height: 4.2rem;
		position: relative;
	}


	.orient_layer {
		position: absolute;
		width: 100%;
		height: 3rem;
		margin-top: -1.5rem;
		text-align: center;
		padding-top: 20px;
		top: 50%;
	}

	.orient_layer_icon {
		width: 1.3rem;
		height: 2rem;
		background-image: url(../../assets/mobile/landscape.png);
		background-size: 100% 100%;
		display: inline-block;
	}

	.orient_layer p {
		color: #f3e2ff;
	}
</style>
