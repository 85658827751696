<template>
	<div id="m_newpage">
		<div class="npage-bg"></div>
		<div class="newinfo-root">
			<div id="m_none"></div>
			<div class="m_npdiv">
				<div class="m_np-title">
					<h1>{{title}}</h1>
				</div>
				<p>{{date}}</p>
				<pre v-html='createDocumentFragment(info).innerHTML'></pre>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'newpage',
		props: {
		  newid: String,
		  title:String,
		  date:String,
		  info:String,
		},
		methods:{
			createDocumentFragment:function(txt){
				const node = document.createElement('div');
				node.style = 'display:inline-block';
				node.innerHTML = txt;
				return node;
			},
		}
	}
</script>

<style>
	#m_newpage .npage-bg{
		width: 100%;
		height: 26.041666666vw;
		background-image: url(../../assets/mobile/newpage-bg.jpg);
		background-size: 100% 100%;
	}
	#m_newpage .newinfo-root{
		font-family: "SourceHanSerifCN-Bold";
		width: 100%;
		background-color: #dacee4;
	}
	.m_npdiv{
		width: 7rem;
		padding-bottom: 2rem;
		margin: 0 auto;
		text-align: left;
		font-size: 0.22rem;
	}
	.m_npdiv pre{
		white-space: pre-line;
	}
	.m_npdiv p{
		margin: 0;
		padding: 0;
		margin-top: 0.10rem;
		color: #584375;
		
	}
	.m_np-title{
		padding-top: 0.40rem;
		border-bottom: 0.01rem dashed   #7b4117;
		text-align: left;
	}
	.m_np-title h1{
		line-height: 0.60rem;
		font-weight: 600;
		font-size: 0.28rem;
		color: #2c1b3a;
	}
	
	.m_npdiv .infos{
		padding-top: 0.60rem;
		text-align: left;
	}
	.m_npdiv .infos img{
		padding-top: 0.10rem;
		width: 100%;
	}
	.m_npdiv .infos pre,
	.m_npdiv .infos p{
		color: #584375;
		line-height: 0.30rem;
		padding: 0;
		margin: 0;
	}
</style>
