<script>
	export default{
		
		get_news_list(){
			return [
				require("../assets/news/news_2022_02_10.js").news,
			]
		},
		
	}
</script>