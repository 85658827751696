<template>
  <div id="app">
	  <div v-if="isMobile">
		<indexm/>
	  </div>
	  <div v-if="!isMobile">
		  <indexpc/>
	  </div>
  </div>
</template>

<script>

import indexm from './views/mobile/index.vue'
import indexpc from './views/pc/index.vue'

export default {
  name: 'app',
  components: {
    indexm,
	indexpc
  },
  data(){
    return {
		isMobile:true,
	};
  },
  methods:{
	px2rem:function(){
		var size = this.isMobile?750:1920;
		var e = function(){
			var cw = document.documentElement.clientWidth > size ? size : document.documentElement.clientWidth;
			cw && (document.documentElement.style.fontSize = cw / size * 100 + "px");
		};
		e();
		var ev = "orientationchange" in window ? "orientationchange" : "resize";
		window.addEventListener && window.addEventListener(ev, e, false);
		document.addEventListener && document.addEventListener("DOMContentLoaded", e, false);
	}
  },
  mounted() {
	//暂时注释
	this.isMobile = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/ig);
	
	this.px2rem();
  }
}


</script>

<style>
	@import url("./assets/css/swiper.min.css");
	@import url("assets/css/base.css");
	
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 18px;
}
</style>
